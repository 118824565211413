import { useDialogContext } from '@/context/DialogContext/DialogContext'
import { GenericModalProps } from './useGenericModal.types'

const useGenericModal = ({
	closeButtonClearNewInfos,
	title,
	body,
}: GenericModalProps): { showModal: (msg?: string) => Promise<boolean> } => {
	const { showDialog, resetDialog } = useDialogContext()

	const showModal = (msg?: string): Promise<boolean> => {
		return new Promise((resolve) => {
			showDialog({
				title,
				open: true,
				body: msg ?? body,
				closeButton: {
					children: closeButtonClearNewInfos,
					onClick: () => {
						resetDialog()
						resolve(false)
					},
				},
			})
		})
	}
	return { showModal }
}

export default useGenericModal
