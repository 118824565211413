import { fieldCurrencyValidationRequired } from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const pricePerPointSchema = Yup.object().shape({
	pricePerPoint: fieldCurrencyValidationRequired(),
})

const EditRedeemableItemFormProvider: React.FC = ({ children }) => {
	const formMethods = useForm<{ pricePerPoint: number | undefined }>({
		resolver: yupResolver(pricePerPointSchema),
		mode: 'onChange',
		shouldFocusError: true,
	})
	return (
		<FormProvider {...formMethods}>
			<form>{children}</form>
		</FormProvider>
	)
}

export default EditRedeemableItemFormProvider
