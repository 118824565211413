import ToastTemplate from '@/components/templates/ToastTemplate'
import SkuForm from '@/domains/sku/SkuForm'
import ProductColumn from '@/routes/rules/RulesRedesign/Components/ProductColumn/ProductColumn'
import { Alert, LoadingBuzz, SearchField } from '@hexa-ui/components'
import { Search } from '@hexa-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyItemImage from './EmptyItemImage'
import NotFoundedImage from './NotFoundedImage'
import Syled from './StepSingleSkuComponentStyle'

type SingleSkuProps = {
	handleSearch: () => void
	handleClear: () => void
	skuItem?: SkuForm
	itemNotFound: boolean
	isLoading: boolean
	setSearchText: (text: string) => void
}

const StepSingleSkuComponentView: React.FC<SingleSkuProps> = ({
	handleSearch,
	handleClear,
	skuItem,
	itemNotFound,
	isLoading,
	setSearchText,
}) => {
	const { t } = useTranslation()

	return (
		<div>
			<ToastTemplate />
			<div
				style={{
					display: 'grid',
					gridGap: '20px',
					gridTemplateColumns: '1fr 118px',
					alignItems: 'center',
					margin: '10px 0',
				}}
			>
				<SearchField.Root
					size="medium"
					data-testid="earning-rule-sku-search"
					placeholder={t('rules:SEARCH_BY_SKU_ID')}
					onKeyPress={(ev: { charCode: number }) => ev.charCode === 13 && handleSearch()}
					onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setSearchText(ev.target.value)}
					onClear={handleClear}
				/>
				<Syled.SearchButton icon={Search} leading size="medium" variant="secondary" onClick={handleSearch}>
					{t('common:BUTTON.SEARCH')}
				</Syled.SearchButton>
			</div>
			<div>
				<Alert
					css={{ width: '100%', border: '1px solid #D5E9FE' }}
					message={t('rules:STEPS.SKUS.EXPIRING_ITEMS_MESSAGE')}
				/>
			</div>
			{isLoading ? (
				<Syled.EmptySkuContainer>
					<div style={{ padding: '16px' }}>
						<LoadingBuzz size="xxlarge" />
					</div>
				</Syled.EmptySkuContainer>
			) : (
				<Syled.ItemCard>
					{itemNotFound && <NotFoundedImage />}
					{skuItem?.id && <ProductColumn sku={skuItem} />}
					{!itemNotFound && !skuItem?.id && <EmptyItemImage />}
				</Syled.ItemCard>
			)}
		</div>
	)
}

export default StepSingleSkuComponentView
