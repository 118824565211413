export const formatDateTimeZone = (
	preferences: {
		date: string
		preferredLanguage: string | undefined
		preferredTimeFormat?: string
		timeZone?: string
	},
	dateOptions?: {
		hasHour?: boolean
		hasDay?: boolean
		month?: 'numeric' | '2-digit' | 'short' | 'long' | 'narrow'
	},
): string => {
	if (!preferences.date || !preferences.preferredLanguage) return ''

	const { date, timeZone, preferredLanguage: locales, preferredTimeFormat } = preferences

	const newDate = new Date(date)
	const dateOption: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: dateOptions?.month ?? undefined,
		day: dateOptions?.hasDay ? 'numeric' : undefined,
		hour: dateOptions?.hasHour ? '2-digit' : undefined,
		minute: dateOptions?.hasHour ? '2-digit' : undefined,
		timeZone: timeZone ?? undefined,
		hour12: preferredTimeFormat ? preferredTimeFormat !== 'H:mm' : undefined,
	}

	const formattedDate = new Intl.DateTimeFormat(locales, dateOption).format(newDate)

	return formattedDate.replace(/,\s*(?=\d{2}:\d{2})/, ' - ')
}
