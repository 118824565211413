import { styled } from '@bees/vision-tokens'
import { TextButton } from '@hexa-ui/components'

export const AudienceCardContent = styled('div', {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '16px',
})

export const AudienceCardItem = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	gap: '8px',
	wordBreak: 'break-word',
	minWidth: '33%',
})

export const AudienceCardTraits = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: '16px',
})

export const AudienceCardShowAllButton = styled(TextButton, {
	color: '#0363C4 !important',
})

export const AudienceCardQuantityTraits = styled('div', {
	fontSize: '16px',
	lineHeight: '24px',
})
