import React, { useState } from 'react'
import { Heading, Paragraph } from '@hexa-ui/components'
import { AudienceObject, AudienceSelector } from '@dlb-shared-business-components/audience'
import useGetUserInfo from '@/hooks/useInfo/useGetUserInfo'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useTranslation } from 'react-i18next'
import { CardAudienceExclusionEditData } from './AudienceExclusionEditData.styles'

export const AudienceExclusionEditData = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const vendorId = getDefaultVendorIdOfCurrentZone()
	const [selectedAudience, setSelectedAudience] = useState<AudienceObject | null>(null) // TO-DO in integration and for DRAFT

	const handleSelectAudience = (audienceSelected: AudienceObject) => {
		setSelectedAudience(audienceSelected)
	}

	return (
		<CardAudienceExclusionEditData border="medium" elevated="small" data-testid="exclusion-rule-page-audience">
			<>
				<Heading id="audience-title" size="H3">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.TITLE')}
				</Heading>
				<Paragraph colortype="secondary" weight="medium">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.INFO_TEXT')}
				</Paragraph>
			</>
			<AudienceSelector
				country={userInfo?.zone ?? ''}
				vendorId={vendorId}
				onSelectAudience={handleSelectAudience}
				selectedAudienceId={selectedAudience?.audienceId ?? undefined} // TO-DO in integration and for DRAFT
				isSearchable
				enableCreation
				audienceCreatorProps={{ popToggleIsEnabled: false }}
				profile="membership"
			/>
		</CardAudienceExclusionEditData>
	)
}
