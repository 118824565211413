import { useLogService as logService } from 'admin-portal-shared-services'
import axios from '@/utils/axiosInstance'
import { TransactionState } from '@/stores/transactionV2/TransactionStore'
import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'
import AddPointsTransaction from '@/domains/transaction/AddPointsTransaction'

const endPointsRewardsAdminServiceV1 = '/rewards-admin-service/v1/'
const endPointsRewardsAdminServiceV2 = '/rewards-admin-service/v2/'
const endPointsRewardsAdminServiceV3 = '/rewards-admin-service/v3/'
const log = logService()

async function load(vendorId: string, pagination?: { page?: number; pageSize?: number }): Promise<TransactionState> {
	const config = { params: { vendorId, ...pagination } }
	const endPoint = `${endPointsRewardsAdminServiceV2}transactions`

	try {
		const response = await axios.get<TransactionState>(endPoint, config)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)

		throw error
	}
}

async function getTransaction(vendorId: string, transactionId: string): Promise<TransactionResponseV2> {
	const endPoint = `${endPointsRewardsAdminServiceV2}transactions/${transactionId}/details/${vendorId}`

	try {
		const response = await axios.get<TransactionResponseV2>(endPoint)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)

		throw error
	}
}

async function addPoints(addPointsTransaction: AddPointsTransaction, fileName?: string, file?: File): Promise<void> {
	const endPoint = `${endPointsRewardsAdminServiceV3}transactions`
	const addPointsTransactionData = {
		type: 'OFFER',
		quantity: addPointsTransaction.quantity ?? 1,
		fileName: fileName ?? '',
		...addPointsTransaction,
	}
	const formData = new FormData()
	formData.append('data', JSON.stringify(addPointsTransactionData))
	if (file) {
		formData.append('file', file)
	}

	try {
		const response = await axios.post<void>(endPoint, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		return response?.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function pointsRemoval(
	data: {
		vendorId: string
		accounts: Array<{ vendorAccountId: string; points: string; campaignId: string }>
		quantity: number
		description: string
		category: string
	},
	file?: File,
): Promise<void> {
	const endPoint = `${endPointsRewardsAdminServiceV3}transactions`

	const PointsRemovalData = {
		type: 'REMOVE',
		singlePOC: data.quantity === 1,
		...data,
	}

	const formData = new FormData()
	formData.append('data', JSON.stringify(PointsRemovalData))
	if (file) {
		formData.append('file', file)
	}

	try {
		const response = await axios.post<void>(endPoint, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function downloadCSV(accountId: string, id: string, status: string): Promise<Blob | null> {
	const baseEndpoint = `${endPointsRewardsAdminServiceV1}transactions/${accountId}/${id}/csv`
	const statusQuery = status === 'PENDING' ? 'PROCESSING&type=MANUAL_APPROVAL' : status
	const endpoint = `${baseEndpoint}?status=${statusQuery}`
	try {
		const response = await axios.get(endpoint)

		return response.data
	} catch (error) {
		console.error(`Error downloading CSV: ${endpoint}`)
		return null
	}
}

async function cancelTransaction(transactionId: string): Promise<number | null> {
	const endPoint = `${endPointsRewardsAdminServiceV1}transactions/${transactionId}`

	try {
		const response = await axios.delete(endPoint)
		return response.status
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		return null
	}
}

export { load, getTransaction, addPoints, pointsRemoval, downloadCSV, cancelTransaction }
