import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { Heading, Paragraph, Input } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Content, Header } from './SettingsCard.styles'

interface SettingsCardProps {
	readonly isEarning: boolean
}

export function SettingsCard({ isEarning }: SettingsCardProps) {
	const { t } = useTranslation()

	return (
		<Card border="medium" elevated="small" data-testid="settings-card">
			<Content>
				<Header>
					<Heading size="H3">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS')}</Heading>

					<Paragraph colortype="secondary">
						{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS_DESCRIPTION')}
					</Paragraph>
				</Header>
				{isEarning && (
					<GridContainer
						style={{ width: '100%', minHeight: '16px', gap: '16px', display: 'grid', gridTemplateColumns: 'auto auto' }}
					>
						<GridItem>
							<Input
								label={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.AMOUNT_OF_POINTS')}
								placeholder={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_POINTS')}
								width="100%"
							/>
						</GridItem>

						<GridItem>
							<Input
								label={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.CURRENCY_AMOUNT')}
								placeholder={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_CURRENCY_AMOUNT')}
								width="100%"
							/>
						</GridItem>
					</GridContainer>
				)}
				<Input
					label={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
					placeholder={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_RULE_DESCRIPTION')}
					width="100%"
				/>
			</Content>
		</Card>
	)
}
