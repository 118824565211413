import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '@hexa-ui/components'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { useGetFeatureTogglesValuesToSelectedInfo } from '@/routes/rules/hooks/useGetFeatureTogglesValuesToSelectedInfo/useGetFeatureTogglesValuesToSelectedInfo'
import { TierMessageProps } from './TierMessage.types'

const DEFAULT_TEXT = 'the rewards program'

export const TierMessage: React.FC<TierMessageProps> = ({ tierMessagePath }) => {
	const { isSelectTiersEnabled } = useGetFeatureTogglesValuesToSelectedInfo()

	const { t } = useTranslation()
	const { currentTier } = useCurrentTier()

	return (
		<Paragraph colortype="secondary" weight="medium">
			{t(tierMessagePath, { tier: isSelectTiersEnabled && currentTier?.label ? currentTier?.label : DEFAULT_TEXT })}
		</Paragraph>
	)
}

export default TierMessage
