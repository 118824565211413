import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Table } from '@hexa-ui/components'
import ProgressiveImage from '@/components/core/ProgressiveImage'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useSkuListDataTable from '@/routes/rules/hooks/useSkuListDataTable'
import { useLoadSkuList } from '../../../hooks/useLoadSkuList'
import { EmptyState } from './Components/EmptyState/EmptyState'
import { SkuListTableProps } from './SkuListTableV2.types'
import { ToolbarExtra } from './Components/ToolbarExtra/ToolbarExtra'

const SkuListTableV2: React.FC<SkuListTableProps> = ({ isEditMode, hasTableSearch, hasToolbarButton, hasDelete }) => {
	const { selectedTierKey } = useCurrentTier()
	const { ruleId } = useParams<{ ruleId: string }>()
	const { dataSkuList } = useSkuListDataTable(hasDelete)
	const { t } = useTranslation()
	const { loadSkuList, loading } = useLoadSkuList(ruleId, selectedTierKey ?? '')

	const columns = [
		{
			Header: 'Preview',
			accessor: 'image',
			disableSortBy: true,
			customRender: (value: string) => <ProgressiveImage imageSource={value} />,
			style: { width: '10%' },
		},
		{
			Header: t('rules:SKUS.LIST.COLUMN_PRODUCT'),
			accessor: 'name',
			disableSortBy: true,
			style: { width: '45%' },
		},
		{
			Header: t('rules:SKUS.LIST.COLUMN_SKU'),
			accessor: 'skuId',
			disableSortBy: true,
			style: { width: '45%' },
		},
		{
			Header: '',
			accessor: 'delete',
			disableSortBy: true,
		},
	]

	useEffect(() => {
		loadSkuList()
	}, [loadSkuList])

	const tableProps = {
		columns,
		data: dataSkuList,
		loading,
		search: isEditMode ? hasTableSearch : undefined,
		searchPlaceholder: t('rules:SKUS.LIST.SEARCH'),
		emptyMessage: <EmptyState />,
		toolbarExtra: isEditMode && hasToolbarButton ? () => <ToolbarExtra /> : undefined,
	}

	return <Table {...tableProps} />
}

SkuListTableV2.displayName = 'SkuListTableV2'

export default SkuListTableV2
