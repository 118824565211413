import React from 'react'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { fieldNumberValidationRequiredMin1AndInteger } from '@/utils/validationObjects'
import * as I from './CreateEarningRuleProvider.types'

const CreateEarningRuleProvider: React.FC<I.CreateEarningRuleProviderProps> = ({ children }) => {
	const formSchema = useForm<I.CreateEarningRuleFormData>({
		mode: 'onChange',
		defaultValues: {
			points: undefined,
			amountSpent: undefined,
		},
		resolver: yupResolver(
			Yup.object().shape({
				points: fieldNumberValidationRequiredMin1AndInteger(),
				amountSpent: fieldNumberValidationRequiredMin1AndInteger(),
			}),
		),
	})
	return <FormProvider {...formSchema}>{children}</FormProvider>
}
export default CreateEarningRuleProvider
