import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { CREATE_EARNING_RULES, CREATE_EXCLUSION_LIST } from '@/utils/constants'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import RuleTypeSelection from './RuleTypeSelection/RuleTypeSelection'
import ActionButtonsWrapper from '../../Components/ActionButtonsWrapper/ActionButtonsWrapper'
import useCardSelection from './hooks/useCardSelection/useCardSelection'
import { SkuModal } from '../../Components/SkuModal/SkuModal'

const CreateRulePageV2: React.FC = () => {
	const { t } = useTranslation()
	const userScopesCreateEarningRules = useScopeAuthorization([CREATE_EARNING_RULES])
	const userScopesCreateExclusionList = useScopeAuthorization([CREATE_EXCLUSION_LIST])
	const { selectedCard, handleCancel, handleSubmit, handleCardClick } = useCardSelection()
	const { goToRulesList, goToCreateRule, goToCreateExclusion, goToCreateEarningRule } = useNavigateToRules()
	const [isModalOpened, setIsModalOpened] = useState(false)

	useEffect(() => {
		const actionMap: { [key: string]: () => void } = {
			'false-true': goToCreateExclusion,
			'true-false': goToCreateEarningRule,
			'false-false': goToRulesList,
			'true-true': goToCreateRule,
		}

		const key = `${userScopesCreateEarningRules}-${userScopesCreateExclusionList}`
		const action = actionMap[key]
		return action()
	}, [
		goToCreateExclusion,
		goToCreateEarningRule,
		goToCreateRule,
		goToRulesList,
		userScopesCreateEarningRules,
		userScopesCreateExclusionList,
	])

	return (
		<ActionButtonsWrapper
			onActionCancel={handleCancel}
			onActionSubmit={handleSubmit}
			buttonLabelActionCancel={t('common:BUTTON.BACK')}
			buttonLabelActionSubmit={t('common:BUTTON.CONTINUE')}
			buttonVariantActionCancel="secondary"
			buttonVariantActionSubmit="primary"
			isSubmitButtonDisabled={!selectedCard}
		>
			<RuleTypeSelection onCardSelect={handleCardClick} />
		</ActionButtonsWrapper>
	)
}

export default CreateRulePageV2
