import React, { useEffect } from 'react'
import { Paragraph } from '@hexa-ui/components'
import { setAppHeaderConfig } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import useCreateRulesHeaderPayload from '@/routes/rules/hooks/useCreateRulesHeaderPayload/useCreateRulesHeaderPayload'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EXCLUSION_LIST } from '@/utils/constants'
import ActionButtonsWrapper from '../../Components/ActionButtonsWrapper/ActionButtonsWrapper'
import AudienceExclusion from './components/AudienceExclusion/AudienceExclusion'
import { SettingsExclusion } from './components/SettingsExclusion/SettingsExclusion'
import { useHandlersExclusionActionsButtons } from './hooks/useHandlersExclusionActionsButtons/useHandlersExclusionActionsButtons'
import { usePermissionScope } from './hooks/usePermissionScope/usePermissionScope'

const CreateExclusionPageRule: React.FC = () => {
	usePermissionScope()
	const { t } = useTranslation()
	const { currentTier } = useCurrentTier()
	const requiredScopes = [CREATE_EXCLUSION_LIST]
	const hasGlobalManagerScope = useScopeAuthorization(requiredScopes)
	const actionsButtonsProps = useHandlersExclusionActionsButtons()

	const basePath = '/membership/rules/exclusion-rule'
	const { isEditMode, setEditMode } = useEditMode(basePath, requiredScopes)

	const handleDeleteRule = () => {
		// Implement delete modal logic here
	}

	const handleEditRule = () => {
		setEditMode()
	}

	const headerPayload = useCreateRulesHeaderPayload(handleDeleteRule, handleEditRule, hasGlobalManagerScope)

	useEffect(() => {
		setAppHeaderConfig(!isEditMode ? headerPayload : { optionalButtons: [] })
	}, [headerPayload, isEditMode])

	return (
		<ActionButtonsWrapper {...actionsButtonsProps}>
			<Paragraph colortype="secondary" weight="medium">
				{t('rules:CREATE_EXCLUSION_RULES.EXCLUSION_DESCRIPTION', { tier: currentTier?.label })}
			</Paragraph>
			<SettingsExclusion />
			<AudienceExclusion />
		</ActionButtonsWrapper>
	)
}

export default CreateExclusionPageRule
