import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import ScreenName from '@/domains/analytics/ScreenName'
import { BASE_ROUTE } from '@/utils/constants'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@/utils/date'
import { useExclusionRulesData } from '@/hooks/exclusionRules/useExclusionRulesData'
import { useRuleSelectedStatus } from '@/context/RuleSelectedStatusContext/RuleSelectedStatusContext'
import GenericRulesList, { RenderStatusColumn } from '../components/GenericRuleList/GenericRuleList'

const ExclusionRulesList: React.FC = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const { data: exclusionRules } = useExclusionRulesData()
	const { setRuleSelectedStatus } = useRuleSelectedStatus()

	const handleRowClick = (ruleId: string, status: string) => {
		setRuleSelectedStatus(status ?? null)
		history.push({
			pathname: `${BASE_ROUTE}/rules/exclusion-rule/${ruleId}`,
			search: status === 'DRAFT' ? '?action=edit' : '?action=view',
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: t('rules:EARNING_RULES_TABLE.RULE_DESCRIPTION'),
				accessor: 'description',
				disableSortBy: false,
				style: { width: '30%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.AUDIENCE'),
				accessor: 'audienceName',
				disableSortBy: false,
				style: { width: '20%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.EDITED_ON'),
				accessor: 'updatedAt',
				disableSortBy: false,
				Cell: (date: { value: Date }) => formatDate(date.value, 'DD MMMM, YYYY'),
				style: {
					width: '10%',
				},
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.STATUS'),
				accessor: 'status',
				disableSortBy: true,
				style: { width: '10%' },
				customRender: RenderStatusColumn,
			},
		],
		[t],
	)

	return (
		<GenericRulesList
			loading={false}
			screenName={ScreenName.ExclusionRules}
			tableName={ScreenName.ExclusionRules}
			description="rules:EARNING_RULES_TABLE.EXCLUSION_LIST_DESCRIPTION"
			data={exclusionRules?.content}
			columns={columns}
			handleRowClick={handleRowClick}
		/>
	)
}

export default ExclusionRulesList
