import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GridContainer } from '@/components/templates/GridTemplate'
import { TABLE_PAGINATION_CONFIG } from '@/utils/constants'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { Paragraph, Table } from '@hexa-ui/components'
import { ScreenName } from '@/domains'
import StatusBadgeColumn from '@/routes/rules/RulesRedesign/Components/StatusBadgeColumn/StatusBadgeColumn'
import { StatusEnum } from '@/routes/rules/RulesRedesign/Components/StatusBadgeColumn/StatusBadgeColumn.types'
import { TableContainer } from './GenericRuleList.styles'

export const RenderStatusColumn = (text: string) => {
	const status = text || StatusEnum.ACTIVE
	return <StatusBadgeColumn status={status as StatusEnum} />
}

type PageData = {
	page: number
	pageSize: number
}

export interface GenericRulesListProps {
	screenName: ScreenName
	tableName: string
	description: string
	data?: any[]
	columns: any[]
	loading: boolean
	ruleFilter?: React.Dispatch<React.SetStateAction<string>>
	handleRowClick: ((ruleId: string, status: string) => void) | ((ruleId: string) => void)
}

const GenericRulesList: React.FC<GenericRulesListProps> = ({
	screenName,
	tableName,
	description,
	data = [],
	ruleFilter,
	loading,
	columns,
	handleRowClick,
}) => {
	const [pageData, setPageData] = useState<PageData>({ page: 1, pageSize: 10 })
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()

	const pageDataEventParams = useMemo(
		() => ({
			items_total: `${data.length}`,
			items_per_page: `${pageData.pageSize}`,
			page_number: `${pageData.page}`,
			table_name: tableName,
			screen_name: screenName,
		}),
		[data.length, pageData.page, pageData.pageSize, tableName, screenName],
	)

	useEffect(() => {
		if (data.length) {
			AnalyticsService.events.tableViewed({
				...pageDataEventParams,
				...userInfo,
			})
		}
	}, [data.length, pageDataEventParams, userInfo])

	const handlePagination = useCallback(
		(page: number, pageSize: number) => {
			if (pageSize !== pageData.pageSize) {
				AnalyticsService.events.tableItemsPerPageChanged({
					...pageDataEventParams,
					...userInfo,
					items_per_page: `${pageSize}`,
				})
			}
			if (page !== pageData.page) {
				AnalyticsService.events.paginationClicked({
					interaction_type: null,
					page_number: `${page}`,
					screen_name: screenName,
					...userInfo,
				})
			}
			setPageData({ page, pageSize })
		},
		[pageDataEventParams, pageData, userInfo, screenName],
	)

	return (
		<GridContainer>
			<Paragraph colortype="secondary" weight="medium">
				{t(description)}
			</Paragraph>

			<TableContainer>
				<Table
					columns={columns}
					data={data}
					loading={loading}
					emptyMessage={t('EMPTY_DATA_TABLE')}
					pagination={{
						...TABLE_PAGINATION_CONFIG,
						/* istanbul ignore next */
						onChange: handlePagination,
					}}
					onRow={(rowData) => ({
						onClick: () => handleRowClick(rowData.ruleId, rowData?.status),
						style: { cursor: 'pointer' },
					})}
					search
					onSearchChange={(event) => {
						if (ruleFilter) ruleFilter(event)
						AnalyticsService.events.tableSearched({
							search_query: event,
							content_type: screenName,
							table_name: tableName,
							screen_name: screenName,
							...userInfo,
						})
					}}
				/>
			</TableContainer>
		</GridContainer>
	)
}

export default GenericRulesList
