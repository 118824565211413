import { getEarningRuleById } from '@/services/rules/RulesService'
import { useQuery } from 'react-query'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useGetLoadAllItemsByRuleId } from '@/services/settings/hooks/useGetLoadAllItemsByRule'
import { useMemo } from 'react'
import useCurrentSettings from '../settings/useCurrentSettings'

export const useGetEarningRuleById = () => {
	const { selectedTierKey } = useStore(GlobalStore)
	const settings = useCurrentSettings()
	const { vendorId } = settings

	const ruleIds = getRuleIdFromPathname()

	const { data, isLoading } = useQuery(
		['earning-rule-by-id', vendorId, selectedTierKey, ruleIds],
		() =>
			getEarningRuleById({
				tier: selectedTierKey,
				vendorId,
				ruleIds,
			}),
		{
			enabled: !!ruleIds,
		},
	)

	return {
		data: data?.content?.[0],
		isLoading,
	}
}

export const useGetEarningRuleByIdWithSkusNormalized = () => {
	const { data, isLoading: isLoadingById } = useGetEarningRuleById()

	const { data: dataLoadAll, isLoading: isLoadingAll } = useGetLoadAllItemsByRuleId({
		skuIds: data?.items,
	})

	const dataNormalized = useMemo(() => {
		return {
			...data,
			items: dataLoadAll?.map((item) => ({
				id: item?.id,
				name: item?.itemName,
				itemId: item?.vendorItemId,
				sku: item?.sku,
				image: item?.itemImage,
				vendorItemId: item.vendorItemId,
			})),
		}
	}, [data, dataLoadAll])

	return {
		data: dataNormalized,
		isLoading: isLoadingById || isLoadingAll,
	}
}
