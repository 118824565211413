import React, { useEffect } from 'react'
import { setAppHeaderConfig } from 'admin-portal-shared-services'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import useCreateRulesHeaderPayload from '@/routes/rules/hooks/useCreateRulesHeaderPayload/useCreateRulesHeaderPayload'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EARNING_RULES } from '@/utils/constants'
import { TierMessage } from '../../Components/TierMessage/TierMessage'
import * as Style from './CreateEarningRule.styles'
import { ActionsButtons } from './components/ActionsButtons/ActionsButtons'
import { Settings } from './components/Settings/Settings'
import { SkuList } from './components/SkuList/SkuList'
import { usePermissionScope } from './hooks/usePermissionScope/usePermissionScope'

const CreateEarningRule: React.FC = () => {
	const requiredScopes = [CREATE_EARNING_RULES]
	const hasGlobalManagerScope = useScopeAuthorization(requiredScopes)
	const basePath = '/membership/rules/earning-rule'
	const { setEditMode, isEditMode } = useEditMode(basePath, requiredScopes)
	usePermissionScope()

	const handleDeleteRule = () => {
		// Implement delete modal logic here
	}

	const handleEditRule = () => {
		setEditMode()
	}

	const headerPayload = useCreateRulesHeaderPayload(handleDeleteRule, handleEditRule, hasGlobalManagerScope)

	useEffect(() => {
		setAppHeaderConfig(!isEditMode ? headerPayload : { optionalButtons: [] })
	}, [headerPayload, isEditMode])

	return (
		<Style.Container>
			<PageLevelTemplate />
			<TierMessage tierMessagePath="rules:CREATE_EARNING_RULES.EARNING_DESCRIPTION" />
			<Settings />
			<SkuList />
			<ActionsButtons />
		</Style.Container>
	)
}

export default CreateEarningRule
