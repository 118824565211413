import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconProps, ParAddtolist } from '@hexa-ui/icons'
import { useHistory } from 'react-router-dom'
import { UseHandlersExclusionActionsButtonsReturn } from './useHandlersExclusionActionsButtons.types'

const EXCLUSION_VARIANT_COLOR = 'secondary'

export const useHandlersExclusionActionsButtons = (): UseHandlersExclusionActionsButtonsReturn => {
	const { t } = useTranslation()
	const history = useHistory()
	const userInfo = useGetUserInfo()

	const exclusionFlowButtonLabelActionSaveAndExit = useMemo(
		() => t('rules:CREATE_EXCLUSION_RULES.EXCLUSION_SECTION.SAVE_AND_EXIT'),
		[t],
	)
	const exclusionFlowButtonLabelActionCancel = useMemo(() => t('rules:CREATE_EXCLUSION_RULES.GO_BACK'), [t])
	const exclusionFlowButtonLabelActionSubmit = useMemo(
		() => t('rules:CREATE_EXCLUSION_RULES.CREATE_NEW_EXCLUSION_RULE'),
		[t],
	)

	const exclusionFlowOnActionSaveAndExit = useCallback(() => {
		// TO-DO: Implement save and exit functionality
	}, [])

	const exclusionFlowOnActionCancel = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Cancel',
			button_label: 'Cancel New Exclusion Rule',
			screen_name: 'Earning Rules',
			...userInfo,
		})
		history.goBack()
	}, [history, userInfo])

	const exclusionFlowOnActionSubmit = useCallback(() => {
		// TO-DO: Implement submit functionality
	}, [])

	return {
		buttonLabelActionSaveAndExit: exclusionFlowButtonLabelActionSaveAndExit,
		buttonLabelActionCancel: exclusionFlowButtonLabelActionCancel,
		buttonLabelActionSubmit: exclusionFlowButtonLabelActionSubmit,
		onActionSaveAndExit: exclusionFlowOnActionSaveAndExit,
		onActionCancel: exclusionFlowOnActionCancel,
		onActionSubmit: exclusionFlowOnActionSubmit,
		buttonVariantActionSaveAndExit: EXCLUSION_VARIANT_COLOR,
		buttonVariantActionCancel: EXCLUSION_VARIANT_COLOR,
		isSubmitButtonDisabled: true,
		isSaveAndExitButtonDisabled: true,
		iconLeadingPosition: true,
		icon: ParAddtolist as unknown as React.FunctionComponent<IconProps>,
		showLeaveConfirmationModal: true,
	}
}
