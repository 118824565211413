import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@hexa-ui/components'
import { Edit2 } from '@hexa-ui/icons'

export const ToolbarExtra = () => {
	const { t } = useTranslation()

	return (
		<Button icon={Edit2} leading size="medium" variant="secondary">
			{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ADD_SKUS')}
		</Button>
	)
}
