import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ParAddtolist } from '@hexa-ui/icons'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconProps } from '@admin-portal-shared-components/icons'
import { UseHandlersActionsButtonsReturn } from './useHandlersActionsButtons.types'

const VARIANT_COLOR = 'secondary'

export const useHandlersActionsButtons = (): UseHandlersActionsButtonsReturn => {
	const history = useHistory()
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()

	const buttonLabelActionSaveAndExit = useMemo(() => t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SAVE_AND_EXIT'), [t])
	const buttonLabelActionCancel = useMemo(() => t('rules:CREATE_EARNING_RULES.GO_BACK'), [t])
	const buttonLabelActionSubmit = useMemo(() => t('rules:CREATE_EARNING_RULES.CREATE_NEW_EARNING_RULE'), [t])

	const onActionSaveAndExit = useCallback(() => {
		// TO-DO: Implement save and exit functionality
	}, [])

	const onActionCancel = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Cancel',
			button_label: 'Cancel New Earning Rule',
			screen_name: 'Earning Rules',
			...userInfo,
		})

		history.goBack()
	}, [history, userInfo])

	const onActionSubmit = useCallback(() => {
		// TO-DO: Implement submit functionality
	}, [])

	return {
		buttonLabelActionSaveAndExit,
		buttonLabelActionCancel,
		buttonLabelActionSubmit,
		onActionSaveAndExit,
		onActionCancel,
		onActionSubmit,
		buttonVariantActionSaveAndExit: VARIANT_COLOR,
		buttonVariantActionCancel: VARIANT_COLOR,
		isSubmitButtonDisabled: true,
		isSaveAndExitButtonDisabled: true,
		iconLeadingPosition: true,
		icon: ParAddtolist as unknown as React.FunctionComponent<IconProps>,
		showLeaveConfirmationModal: true,
	}
}
