import { styled } from '@bees/vision-tokens'
import { Card } from '@hexa-ui/components'

export const ContainerCard = styled(Card, {
	display: 'grid',
	padding: '20px',
	variants: {
		isEditMode: {
			true: {
				gridGap: '10px',
			},
			false: {
				gridGap: '20px',
			},
		},
	},
})
