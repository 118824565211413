import React from 'react'
import { useTranslation } from 'react-i18next'
import Papa from 'papaparse'
import { UploadSkuCsvFile } from '@/routes/rules/RulesRedesign/Pages/EditSku/EditSkuStepper/StepSKU/components/UploadSkuCsvFile'

import { loadAllItems } from '@/services/item/ItemService'
import { AxiosError } from 'axios'
import Styled from '../../../../Pages/EditSku/EditSkuStepper/StepSKU/StepMultipleSkuComponent/styles'
import { useSkuModalContext } from '../../context/SkuModalContext'
import { getCsvValidateSchema, parseCSVHandler, parseItemsToSkuTable } from './SkuFileUploaderUtils'

const SkuFileUploader = () => {
	const { t } = useTranslation()
	const { setSkuModalValue, skuModalValue, setError, clearErrors, errors } = useSkuModalContext()
	const csvSchemaValidation = getCsvValidateSchema({
		required: t('ERROR_MESSAGE.EMPTY_FILE_FIELD'),
		fileSize: t('ERROR_MESSAGE.EXCEEDS_MAX_SIZE_1MB'),
		fileType: t('ERROR_MESSAGE.UNSUPPORTED_FILE_FORMAT'),
	})

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.currentTarget
		const file = files?.[0]

		/* istanbul ignore next */
		if (file) {
			Papa.parse(file, {
				header: true,
				skipEmptyLines: true,
				delimiter: ';',
				complete: (results) => {
					const { error, vendorItemIds } = parseCSVHandler(results)

					if (error) {
						setSkuModalValue({ items: [], fileName: '' })
						const message = t('ERROR_MESSAGE.UPLOAD_FAILED', { name: file.name })

						setError(message)
					} else {
						csvSchemaValidation
							.validate([...files])
							.then(async () => {
								try {
									const response = await loadAllItems({ vendorItemIds: vendorItemIds! })

									if (!response.items) setError('There is no items founded')

									const itemsMap = parseItemsToSkuTable(response.items)

									setSkuModalValue({ items: itemsMap, fileName: file.name })
									clearErrors()
								} catch (err) {
									const axiosError = err as { cause: AxiosError }
									const axiosErrorResponse = axiosError.cause.response as { status: number; data: { code: number } }
									setError(
										axiosErrorResponse.status === 404 ? 'There is no items founded' : 'An error ocurred. Try again.',
									)
									setSkuModalValue({ items: [], fileName: '' })
								}
							})
							.catch(({ message }: { message: string }) => {
								setSkuModalValue({ items: [], fileName: '' })
								setError(message)
							})

						// TO DO: ADD SKU DIALOG - trigger segment ? (search for AnalyticsService.events.earningRuleSkUsUpdated)
					}
				},

				transformHeader: /* istanbul ignore next */ (header) => header.toLowerCase(),
			})
		}
	}

	return (
		<Styled.Content>
			<UploadSkuCsvFile
				handleSubmitFile={handleOnChange}
				errors={!!errors}
				errorMessage={errors}
				fileName={skuModalValue.fileName}
				inputName="csv-file"
			/>
		</Styled.Content>
	)
}

export default SkuFileUploader
