import React from 'react'
import RulesProvider from '@/routes/rules/providers/RulesProvider'
import CreateEarningRuleProvider from './context/CreateEarningRuleProvider/CreateEarningRuleProvider'
import CreateEarningRule from './CreateEarningRule'

const CreateEarningRuleWrapper: React.FC = () => (
	<RulesProvider>
		<CreateEarningRuleProvider>
			<CreateEarningRule />
		</CreateEarningRuleProvider>
	</RulesProvider>
)

export default CreateEarningRuleWrapper
