import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph } from '@hexa-ui/components'
import {
	Card,
	ContentCardViewMode,
	ItemStyleExcusionViewMode,
	ItemStyleViewMode,
	SectionStyleViewMode,
} from './SettingsCard.styles'
import StatusIndicator from './SettingsCardStatus/settingsCardStatus'

interface SettingsCardProps {
	readonly isEarning: boolean
}

export function SettingsCardView({ isEarning }: SettingsCardProps) {
	const { t } = useTranslation()

	const rule = '100 points for every R$ 100 spent'
	const amountOfPoints = 2
	const currencyAmount = 2
	const ruleDescription = 'mock-value'

	return (
		<Card border="medium" elevated="small" data-testid="settings-card-view">
			<ContentCardViewMode>
				<Heading size="H3">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS')}</Heading>
				{isEarning ? (
					<>
						<SectionStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.RULE')}</Paragraph>
								<Paragraph>{rule}</Paragraph>
							</ItemStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">
									{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.AMOUNT_OF_POINTS')}
								</Paragraph>
								<Paragraph>{amountOfPoints}</Paragraph>
							</ItemStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">
									{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.CURRENCY_AMOUNT')}
								</Paragraph>
								<Paragraph>{`R$ ${currencyAmount}`}</Paragraph>
							</ItemStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.STATUS')}</Paragraph>
								<Paragraph>
									<StatusIndicator status="active" />
								</Paragraph>
							</ItemStyleViewMode>
						</SectionStyleViewMode>
						<ItemStyleViewMode>
							<Paragraph weight="semibold">
								{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
							</Paragraph>
							<Paragraph>{ruleDescription}</Paragraph>
						</ItemStyleViewMode>
					</>
				) : (
					<ItemStyleExcusionViewMode>
						<div style={{ width: '50%' }}>
							<Paragraph weight="semibold">
								{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
							</Paragraph>
							<Paragraph>{ruleDescription}</Paragraph>
						</div>
						<ItemStyleViewMode>
							<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.STATUS')}</Paragraph>
							<Paragraph>
								<StatusIndicator status="active" />
							</Paragraph>
						</ItemStyleViewMode>
					</ItemStyleExcusionViewMode>
				)}
			</ContentCardViewMode>
		</Card>
	)
}
