import React from 'react'
import { Paragraph } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import useGenericModal from '@/hooks/useGenericModal/useGenericModal'
import {
	AudienceCardContent,
	AudienceCardItem,
	AudienceCardQuantityTraits,
	AudienceCardShowAllButton,
	AudienceCardTraits,
} from './AudienceCard.styles'

export const AudienceCard = () => {
	const { t } = useTranslation()

	const { showModal: showTraitsDialog } = useGenericModal({
		title: t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.TRAITS_LIST'),
		body: 'traits list here',
	})

	return (
		<AudienceCardContent>
			<AudienceCardItem>
				<Paragraph weight="semibold" size="small" data-testid="audience-name">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.AUDIENCE_NAME')}
				</Paragraph>
				<Paragraph>Audience test</Paragraph>
			</AudienceCardItem>

			<AudienceCardItem>
				<Paragraph weight="semibold" size="small">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.ESTIMATED_POCS_TARGETED')}
				</Paragraph>

				<Paragraph>100.500 POCs</Paragraph>
			</AudienceCardItem>

			<AudienceCardItem>
				<Paragraph weight="semibold" size="small">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.TRAITS')}
				</Paragraph>
				<AudienceCardTraits>
					<AudienceCardQuantityTraits data-testid="quantity-traits">3 traits</AudienceCardQuantityTraits>
					<AudienceCardShowAllButton size="small" data-testid="show-all" onClick={() => showTraitsDialog()}>
						{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.SHOW_ALL_TRAITS')}
					</AudienceCardShowAllButton>
				</AudienceCardTraits>
			</AudienceCardItem>
		</AudienceCardContent>
	)
}
