import { useMemo } from 'react'
import { Edit2, Trash2 } from '@admin-portal-shared-components/icons'
import { AppHeaderConfigPayload, OptionalButton } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'

const useCreateRulesHeaderPayload = (
	handleDeleteRule: () => void,
	handleEditRule: () => void,
	hasGlobalManagerScope: boolean,
): AppHeaderConfigPayload => {
	const { t } = useTranslation()
	const headerPayload = useMemo((): AppHeaderConfigPayload => {
		const optionalButtonsFunction = (): [OptionalButton?, OptionalButton?] => {
			const array = []

			if (hasGlobalManagerScope) {
				array.push(
					{
						label: t('rules:CREATE_EARNING_RULES:DELETE_RULE'),
						onClick: handleDeleteRule,
						icon: () => Trash2,
					},
					{
						label: t('rules:CREATE_EARNING_RULES:EDIT'),
						onClick: handleEditRule,
						icon: () => Edit2,
					},
				)
			}

			return array as unknown as [OptionalButton?, OptionalButton?]
		}

		return {
			optionalButtons: optionalButtonsFunction(),
		}
	}, [hasGlobalManagerScope, t, handleDeleteRule, handleEditRule])

	return headerPayload
}

export default useCreateRulesHeaderPayload
