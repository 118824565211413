import axios from '@/utils/axiosInstance'
import { useLogService as logService } from 'admin-portal-shared-services'
import {
	ExclusionListResponse,
	EarningRulesResponse,
	GetEarningRuleByIdProps,
	GetEarningRuleByIdResponse,
	GetExclusionRuleByIdProps,
	GetExclusionRuleByIdResponse,
} from './RulesService.types'

const BASE_ADMIN_URL = `/rewards-admin-service/v1`
const log = logService()

export const loadExclusionRules = async (
	vendorId: string,
	page: number = 0,
	pageSize: number = 25,
): Promise<ExclusionListResponse> => {
	try {
		const response = await axios.get<ExclusionListResponse>(`${BASE_ADMIN_URL}/exclusion-rules`, {
			params: { page, pageSize },
			headers: { ...(vendorId && { vendorId }) },
		})
		return response.data
	} catch (error) {
		log.error('Error fetching exclusion rules', error)
		throw error
	}
}

export const loadEarningRules = async (vendorId: string, tier: string): Promise<EarningRulesResponse> => {
	try {
		const response = await axios.get<EarningRulesResponse>(`${BASE_ADMIN_URL}/earning-rules`, {
			params: { page: 0, pageSize: 25 },
			headers: {
				vendorId,
				tier,
			},
		})
		return response.data
	} catch (error) {
		log.error('Error fetching earning rules', error)
		throw error
	}
}

export const getEarningRuleById = async ({
	ruleIds,
	page = 0,
	pageSize = 25,
	tier,
	vendorId,
}: GetEarningRuleByIdProps): Promise<GetEarningRuleByIdResponse> => {
	try {
		const response = await axios.get<GetEarningRuleByIdResponse>(`${BASE_ADMIN_URL}/earning-rules`, {
			params: { ruleIds, page, pageSize },
			headers: {
				vendorId,
				tier,
			},
		})

		return response.data
	} catch (error) {
		log.error('Error fetching exclusion rule by id', error)
		throw error
	}
}

export const getExclusionRuleById = async ({
	vendorId,
	ruleId,
	page = 0,
	pageSize = 25,
}: GetExclusionRuleByIdProps): Promise<GetExclusionRuleByIdResponse> => {
	try {
		const response = await axios.get<GetExclusionRuleByIdResponse>(`${BASE_ADMIN_URL}/exclusion-rules`, {
			params: { ruleId, page, pageSize },
			headers: { ...(vendorId && { vendorId }) },
		})

		return response.data
	} catch (error) {
		log.error('Error fetching exclusion rule by id', error)
		throw error
	}
}
